import { Box } from "@mui/material";

const UILoading = () => {
    const text = "Cargando..."
    return <Box component={"div"}
        sx={{ height: "100vh", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", }}>
        {text}
    </Box>;
}

export default UILoading;