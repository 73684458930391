import { Option } from "@/components/inputs/select/UISelectProps"
import { SiebelCatalogResponseData } from "@/services/get-catalog-siebel/getSiebelCatalogService"
import { FileToBeValidatedResponse } from "@/services/get-files-to-be-validated/getFilesToBeValidatedService"
import { GetProcessStatusResponse } from "@/services/get-process-status/getProcessStatusService"
import Status, { Estado, estados } from "./processStatus"
import { InfoFiado } from "@/context/infoFiadoContext"
import { ListOfPropertiesResponse } from "@/services/list-of-properties/listOfPropertiesService"

const formatBytes = (bytes?: number, decimals = 2) => {
  if (bytes === undefined || !+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

function removeAccents(str?: string): string | undefined {
  return str?.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

function levenshtein(a: string, b: string): number {
  const matrix = [];
  let i, j;

  if (a.length === 0) return b.length;
  if (b.length === 0) return a.length;

  // Inicializar la primera fila y columna
  for (i = 0; i <= b.length; i++) matrix[i] = [i];
  for (j = 0; j <= a.length; j++) matrix[0][j] = j;

  for (i = 1; i <= b.length; i++) {
    for (j = 1; j <= a.length; j++) {
      if (b.charAt(i - 1) === a.charAt(j - 1)) {
        matrix[i][j] = matrix[i - 1][j - 1];
      } else {
        matrix[i][j] = Math.min(
          matrix[i - 1][j - 1] + 1,
          Math.min(matrix[i][j - 1] + 1, matrix[i - 1][j] + 1)
        );
      }
    }
  }

  return matrix[b.length][a.length];
}

function replaceRegimen(str?: string): string | undefined {
  return str?.replace(/^(R[eé]gimen de las|R[eé]gimen de la|R[eé]gimen de|R[eé]gimen)/i, '').trim();
}

const findClosestOption = (options: { id: string, value: string }[], extra?: { stringValue: string }[]): { id: string, value: string } => {
  if (!extra || extra.length === 0) return { id: "", value: "" };

  const threshold = 0.9;
  const processedOptions = options.map(opt => ({
    ...opt,
    processedValue: removeAccents(opt.value.toLowerCase())
  }));

  const findBestMatch = (processedValue?: string) => {
    let bestMatch = { id: "", value: "", similarity: 0 };
    for (const option of processedOptions) {
      const similarity = compareSubstrings(option.processedValue, processedValue);
      if (similarity > bestMatch.similarity) {
        bestMatch = { id: option.id, value: option.value, similarity };
      }
      if (option.id.toLowerCase() == processedValue) {
        bestMatch = { id: option.id, value: option.value, similarity: 1.0 };
        break;
      }
    }
    return bestMatch;
  };

  // Primera pasada: buscar coincidencias sin modificar
  for (const { stringValue } of extra) {
    const processedValue = removeAccents(stringValue?.toLowerCase());
    const bestMatch = findBestMatch(processedValue);
    if (bestMatch.similarity >= threshold) {
      return { id: bestMatch.id, value: bestMatch.value };
    }
  }

  // Segunda pasada: intentar con replaceRegimen
  for (const { stringValue } of extra) {
    const processedValue = removeAccents(replaceRegimen(stringValue)?.toLowerCase());
    const bestMatch = findBestMatch(processedValue);
    if (bestMatch.similarity >= threshold) {
      return { id: bestMatch.id, value: bestMatch.value };
    }
  }

  return { id: "", value: "" };
};

function compareSubstrings(str1?: string, str2?: string): number {
  const MAX_NGRAMS = 1000;
  const n = 3; // Usando tri-gramas

  const getNGrams = (str?: string): { [key: string]: boolean } => {
    const ngrams: { [key: string]: boolean } = {};
    const limit = Math.min(str?.length ?? 0 - n + 1, MAX_NGRAMS);
    for (let i = 0; i < limit; i++) {
      ngrams[(str ?? "").slice(i, i + n)] = true;
    }
    return ngrams;
  };

  const ngrams1 = getNGrams(str1);
  const ngrams2 = getNGrams(str2);

  let intersectionSize = 0;
  let totalSize = 0;

  for (const gram in ngrams1) {
    if (ngrams2[gram]) intersectionSize++;
    totalSize++;
  }
  for (const gram in ngrams2) {
    if (!ngrams1[gram]) totalSize++;
  }

  return 2 * intersectionSize / totalSize;
}

function getSubstrings(str: string): string[] {
  const substrings = [];
  for (let i = 0; i < str.length; i++) {
    for (let j = i + 1; j <= str.length; j++) {
      substrings.push(str.slice(i, j));
    }
  }
  return substrings;
}

/**
 * 
 * @param time time in seconds
 * @returns 
 */
const delay = (time: number) => {
  const timer = time * 1000;
  return new Promise((resolve, reject) => setTimeout(resolve, timer));
};

const mergeActivityOptions = (options: Option[], extra?: { actividadEconomica: string }[]) => {
  const lastOption = options.length > 0 ? options[options.length - 1] : { id: "0", value: "" };
  const lastId = parseInt(lastOption.id);
  const _extra = extra?.filter((option) => !options.some((o) => o.value === option.actividadEconomica));
  const newOptions = _extra?.map((option, index) => {
    return { id: (lastId + index + 1).toString(), value: option.actividadEconomica };
  });
  return [...options, ...(newOptions ?? [])];
};

const mergeRegimenOptions = (options: Option[], extra?: { regimen: string }[]) => {
  const lastOption = options.length > 0 ? options[options.length - 1] : { id: "0", value: "" };
  const lastId = parseInt(lastOption.id);
  const _extra = extra?.filter((option) => !options.some((o) => o.value === option.regimen));
  const newOptions = _extra?.map((option, index) => {
    return { id: (lastId + index + 1).toString(), value: option.regimen };
  });
  return [...options, ...(newOptions ?? [])];
};


interface RepresentanteLegal {
  documentos: FileToBeValidatedResponse[];
}

interface ObligadoSolidario {
  documentos: FileToBeValidatedResponse[];
  representantesLegales: RepresentanteLegal[];
}

export interface Agrupacion {
  Fiado: {
    documentos: FileToBeValidatedResponse[];
    representantesLegales: RepresentanteLegal[];
  };
  obligadosSolidarios: ObligadoSolidario[];
  properties?: ListOfPropertiesResponse;
}

function transformArray(documents: FileToBeValidatedResponse[]): Agrupacion {
  const result: Agrupacion = {
    Fiado: {
      documentos: [],
      representantesLegales: [],
    },
    obligadosSolidarios: [],
  };

  documents.forEach((document) => {
    if (findType(document.tipo) !== "") {
      if (document.cotejado && document.pertenencia) {
        if (document.pertenencia === "Fiado") {
          result.Fiado.documentos.push(document);
        } else if (document.pertenencia.startsWith("RepresentanteLegalFiado")) {
          const representanteLegalIndex = parseInt(document.pertenencia.split("-")[1]);
          if (!result.Fiado.representantesLegales[representanteLegalIndex]) {
            result.Fiado.representantesLegales[representanteLegalIndex] = {
              documentos: [],
            };
          }
          result.Fiado.representantesLegales[representanteLegalIndex].documentos.push(document);
        } else if (document.pertenencia.startsWith("ObligadoSolidario")) {
          const obligadoSolidarioIndex = parseInt(document.pertenencia.split("-")[1]);
          if (!result.obligadosSolidarios[obligadoSolidarioIndex]) {
            result.obligadosSolidarios[obligadoSolidarioIndex] = {
              documentos: [],
              representantesLegales: [],
            };
          }
          result.obligadosSolidarios[obligadoSolidarioIndex].documentos.push(document);
        } else if (document.pertenencia.startsWith("RepresentanteLegalObligadoSolidario")) {
          const [_, obligadoSolidarioIndex, representanteLegalIndex] = document.pertenencia.split("-");
          const obligadoIndex = parseInt(obligadoSolidarioIndex);
          const representanteIndex = parseInt(representanteLegalIndex);

          if (!result.obligadosSolidarios[obligadoIndex]) {
            result.obligadosSolidarios[obligadoIndex] = {
              documentos: [],
              representantesLegales: [],
            };
          }

          if (!result.obligadosSolidarios[obligadoIndex].representantesLegales[representanteIndex]) {
            result.obligadosSolidarios[obligadoIndex].representantesLegales[representanteIndex] = {
              documentos: [],
            };
          }

          result.obligadosSolidarios[obligadoIndex].representantesLegales[representanteIndex].documentos.push(document);
        }
      }
    }
  });

  return result;
}

function findType(type: string): string {
  switch (type) {
    case "CONSTANCIA_SITUACION_FISCAL":
      return "Constancia de situación fiscal"
    case "INE_IFE":
      return "Identificación oficial";
    case "PASAPORTE":
      return "Identificación oficial"
    case "ACTA_CONSTITUTIVA":
      return "Acta constitutiva"
    case "CARTA_PODER":
      return "Acreditación de representante legal"
    case "PODER":
      return "Acreditación de representante legal"
    default:
      console.log("No se encontro el tipo de documento");
  }
  return "";
}

function findFileByName(result: Agrupacion, nombre: string): FileToBeValidatedResponse | undefined {
  // Buscar en los documentos del fiado
  const fiadoDocumento = result.Fiado.documentos.find((documento) => documento.nombre === nombre);

  if (fiadoDocumento) {
    return fiadoDocumento;
  }

  // Buscar en los documentos de los representantes legales del fiado
  for (const representante of result.Fiado.representantesLegales) {
    const representanteDocumento = representante.documentos.find((documento) => documento.nombre === nombre);

    if (representanteDocumento) {
      return representanteDocumento;
    }
  }

  // Buscar en los documentos de los obligados solidarios
  for (const obligado of result.obligadosSolidarios) {
    const obligadoDocumento = obligado.documentos.find((documento) => documento.nombre === nombre);

    if (obligadoDocumento) {
      return obligadoDocumento;
    }

    // Buscar en los documentos de los representantes legales de los obligados solidarios
    for (const representante of obligado.representantesLegales) {
      const representanteDocumento = representante.documentos.find((documento) => documento.nombre === nombre);

      if (representanteDocumento) {
        return representanteDocumento;
      }
    }
  }

  // Si no se encuentra el documento, devolver undefined
  return undefined;
}

async function handleProcessStatusOld(idProcessFiado: string, processStatus: (params: { idProceso: string }) => Promise<GetProcessStatusResponse>) {
  const responseProcess = await processStatus({ idProceso: idProcessFiado });
  let processStatusResponse: GetProcessStatusResponse | null = null;
  let retries = 0;
  while ((!processStatusResponse || processStatusResponse.idEstatus !== "3") && retries < 240) {
    try {
      processStatusResponse = await processStatus({
        idProceso: responseProcess.idProceso,
      });
    } catch (error) {
      console.log(error);
    }
    if (processStatusResponse && processStatusResponse.idEstatus === "-1") {
      throw { message: "No se pudo finalizar el proceso" + processStatusResponse.estatus, name: "Finalize error" } as Error;
    }
    if (!processStatusResponse || processStatusResponse.idEstatus !== "3") {
      await new Promise((resolve) => setTimeout(resolve, 5000));
    }
    retries++;
  }
  if ((!processStatusResponse || processStatusResponse.idEstatus !== "3") && retries >= 240) {
    throw { message: "No se pudo finalizar el proceso", name: "Finalize error" } as Error;
  }
}

export function getStatusFromIdEstatus(idEstatus: string): Status | undefined {
  const status = idEstatus as Status;

  if (Object.values(Status).includes(status)) {
    return status;
  }

  return undefined;
}

async function handleProcessStatus(
  idProcessFiado: string,
  processStatus: (params: { idProceso: string }) => Promise<GetProcessStatusResponse>,
  setMessage: (message: string) => void,
  completedStatuses: Status[]
) {
  const responseProcess = await processStatus({ idProceso: idProcessFiado });
  let processStatusResponse: GetProcessStatusResponse | null = null;
  let retries = 0;
  const maxRetries = 240;
  const retryDelay = 5; // 5 segundos

  while ((!processStatusResponse || !completedStatuses.includes(getStatusFromIdEstatus(processStatusResponse.idEstatus) as Status)) && retries < maxRetries) {
    try {
      processStatusResponse = await processStatus({
        idProceso: responseProcess.idProceso,
      });

      if (processStatusResponse) {
        const status = getStatusFromIdEstatus(processStatusResponse.idEstatus);
        if (status && status in estados) {
          const currentState: Estado = estados[status];
          setMessage(currentState.message);
          if (currentState.isError || status.includes("error")) {
            throw {
              message: "No se pudo finalizar el proceso: " + currentState.message,
              name: status
            } as Error;
          }
        } else {
          console.warn(`Estado desconocido: ${processStatusResponse.idEstatus}`);
          setMessage(`Estado desconocido: ${processStatusResponse.idEstatus}`);
          if (processStatusResponse && processStatusResponse.idEstatus?.includes("error")) {
            throw {
              message: "No se pudo finalizar el proceso: " + processStatusResponse.estatus,
              name: processStatusResponse.idEstatus
            } as Error;
          }
        }
      }
    } catch (error) {
      console.log(error);
      throw error;
    }

    if (!processStatusResponse || !completedStatuses.includes(getStatusFromIdEstatus(processStatusResponse.idEstatus) as Status)) {
      await delay(retryDelay);
    }
    retries++;
  }

  if ((!processStatusResponse || !completedStatuses.includes(getStatusFromIdEstatus(processStatusResponse.idEstatus) as Status)) && retries >= maxRetries) {
    throw new Error("No se pudo finalizar el proceso después de múltiples intentos");
  }

  return processStatusResponse;
}

function isEmpty(value: string | undefined | null): boolean {
  return value === undefined || value === null || value.trim() === '';
}
function capitalizeFirstLetter(text: string): string {
  if (!text) return text;
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}

function transformToOptions(data: SiebelCatalogResponseData[]): Option[] {
  if (!data) return [];

  return data.map((item) => {
    return {
      id: item.name,
      value: item.value,
    };
  });
}

function cleanString(input: string): string {
  const normalizedString = input.normalize('NFD').replace(/[\u0300-\u036f]/g, '').normalize("NFC");
  return normalizedString.replace(/\s+/g, '_').replace(/[^a-zA-Z0-9_]/g, '');
}
// Función para validar el formato de fecha dd/mm/yyyy
const isValidDate = (dateString: String) => {
  const regex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
  const parts = dateString.match(regex);
  if (!parts) return false;
  const day = parseInt(parts[1], 10);
  const month = parseInt(parts[2], 10) - 1; // Los meses son 0-indexados
  const year = parseInt(parts[3], 10);
  const date = new Date(year, month, day);
  return date.getDate() === day && date.getMonth() === month && date.getFullYear() === year;
};


/**
 * Intenta ejecutar una función que devuelve una promesa hasta un número máximo de veces especificado.
 * Si la promesa se resuelve correctamente, devuelve el resultado de la promesa.
 * Si la promesa es rechazada, reintentará ejecutar la función hasta alcanzar el número máximo de intentos.
 * Si después de los intentos máximos la promesa sigue siendo rechazada, propaga el error.
 *
 * @template T El tipo de dato que la promesa resuelve.
 * @param {() => Promise<T>} promiseFn La función que devuelve una promesa a intentar.
 * @param {number} [maxRetries=3] El número máximo de intentos para ejecutar la promesa, 3 reintentos por defecto.
 * @returns {Promise<T>} Una promesa que resuelve con el valor de la promesa original si es exitosa,
 *                       o es rechazada después de alcanzar el número máximo de intentos.
 */
function retryPromise<T>(promiseFn: () => Promise<T>, maxRetries: number = 3): Promise<T> {
  let attempts = 0;

  const attempt = (): Promise<T> => {
    attempts++;
    return promiseFn().catch((error) => {
      if (attempts <= maxRetries) {
        return attempt(); // Reintenta
      } else {
        console.log(`Intento ${attempts}: falló, no hay más intentos.`);
        console.log(error);
        throw error; // Propaga el error después de los intentos
      }
    });
  };

  return attempt(); // Inicia el primer intento
}

const fixState = function (data: string): string {
  const stateReplacements: Record<string, string> = {
    'ciudad de m[ée]xico': 'CDMX',
    'm[ée]xico': 'Estado de México',
    'veracruz de ignacio de la llave': 'Veracruz',
    'michoac[áa]n de ocampo': 'Michoacán',
    'pachuca de soto': 'Pachuca',
  };

  const formattedData = data.toLowerCase();

  for (const [pattern, replacement] of Object.entries(stateReplacements)) {
    const regex = new RegExp(`\\b${pattern}\\b`, 'i');
    if (regex.test(formattedData)) {
      return replacement;
    }
  }

  return data;
};

const redirectEdit = (setData: React.Dispatch<React.SetStateAction<InfoFiado | undefined>>, idProcessFiado: string, push: (href: string, options?: import("next/dist/shared/lib/app-router-context.shared-runtime").NavigateOptions) => void) => {
  setData((prevData) => ({
    ...prevData, // Copia todas las propiedades del estado anterior
    edit: false, // Sobrescribe la propiedad edit a false
    update: prevData?.update ?? false // Asegúrate de que update siempre sea boolean
  }));
  push("/create/summary?idProcess=" + idProcessFiado);
  return;
}

export { levenshtein, formatBytes, getSubstrings, findClosestOption, delay, mergeActivityOptions, mergeRegimenOptions, transformArray, findType, findFileByName, handleProcessStatus, isEmpty, capitalizeFirstLetter, transformToOptions, isValidDate, retryPromise, fixState, handleProcessStatusOld, cleanString, redirectEdit }
